const fr_FR = {
  pluralize (n) {
    if (n === 1) {
      return 0
    }
    return 1
  },
}

fr_FR.strings = {
  addBulkFilesFailed: {
    '0': 'L\'ajout de %{smart_count} fichier a échoué',
    '1': 'L\'ajout de %{smart_count} fichiers a échoué',
  },
  addMore: 'Ajouter d\'autres',
  addMoreFiles: 'Ajouter d\'autres fichiers',
  addingMoreFiles: 'En train d\'ajouter des fichiers',
  allFilesFromFolderNamed: 'Tous les fichiers du dossier %{name}',
  allowAccessDescription: 'Pour prendre des photos ou enregistrer une vidéo avec votre caméra, veuillez autoriser l\'accès à votre caméra pour ce site.',
  allowAccessTitle: 'Veuillez autoriser l\'accès à votre caméra',
  authAborted: 'Authentification interrompue',
  authenticateWith: 'Se connecter à %{pluginName}',
  authenticateWithTitle: 'Veuillez vous authentifier avec %{pluginName} pour sélectionner les fichiers',
  back: 'Retour',
  browse: 'naviguer',
  browseFiles: 'naviguer',
  cancel: 'Annuler',
  cancelUpload: 'Annuler téléchargement',
  chooseFiles: 'Choisir des fichiers',
  closeModal: 'Fermer Fenêtre',
  companionError: 'Connexion à Companion a échoué',
  companionUnauthorizeHint: 'Pour vous déconnecter de votre compte %{provider}, veuillez aller à %{url}',
  complete: 'Terminé',
  compressedX: '%{size} économisé(s) par la compression',
  compressingImages: 'Compression des images...',
  connectedToInternet: 'Connecté à Internet',
  copyLink: 'Copier lien',
  copyLinkToClipboardFallback: 'Copier le lien ci-dessous',
  copyLinkToClipboardSuccess: 'Lien copié',
  creatingAssembly: 'Préparation du téléchargement...',
  creatingAssemblyFailed: 'Transloadit: Impossible de créer Assembly',
  dashboardTitle: 'Téléchargeur de fichier',
  dashboardWindowTitle: 'Fenêtre de téléchargeur de fichier (Appuyez sur echap pour fermer)',
  dataUploadedOfTotal: '%{complete} sur %{total}',
  done: 'Terminé',
  dropHereOr: 'Déposer les fichiers ici ou %{browse}',
  dropHint: 'Déposez vos fichiers ici',
  dropPasteBoth: 'Déposer les fichiers ici, coller ou %{browse}',
  dropPasteFiles: 'Déposer les fichiers ici, coller ou %{browse}',
  dropPasteFolders: 'Déposer les fichiers ici, coller ou %{browse}',
  dropPasteImportBoth: 'Déposer les fichiers ici, coller, %{browse} ou importer de',
  dropPasteImportFiles: 'Déposer les fichiers ici, coller, %{browse} ou importer de',
  dropPasteImportFolders: 'Déposer les fichiers ici, coller, %{browse} ou importer de',
  editFile: 'Modifier le fichier',
  editFileWithFilename: 'Modifier le fichier %{file}',
  editing: 'Modification en cours de %{file}',
  emptyFolderAdded: 'Aucun fichier n\'a été ajouté depuis un dossier vide',
  encoding: 'Traitement...',
  enterCorrectUrl: 'Lien incorrect: Assurez-vous que vous entrez un lien direct vers le fichier',
  enterUrlToImport: 'Entrez le lien pour importer un fichier',
  exceedsSize: 'Ce fichier dépasse la taille maximale autorisée de %{size}',
  failedToFetch: 'Companion a échoué à récupérer ce lien, assurez-vous qu\'il est correct',
  failedToUpload: 'Le téléchargement de %{file} a échoué',
  fileSource: 'Fichier source: %{name}',
  filesUploadedOfTotal: {
    '0': '%{complete} sur %{smart_count} fichier téléchargé',
    '1': '%{complete} sur %{smart_count} fichiers téléchargés',
    '2': '%{complete} sur %{smart_count} fichiers téléchargés',
  },
  filter: 'Filtrer',
  finishEditingFile: 'Terminer l\'édition du fichier',
  folderAdded: {
    '0': '%{smart_count} fichier ajouté de %{folder}',
    '1': '%{smart_count} fichiers ajoutés de %{folder}',
    '2': '%{smart_count} fichiers ajoutés de %{folder}',
  },
  generatingThumbnails: 'Generating thumbnails...',
  import: 'Importer',
  importFrom: 'Importer de %{name}',
  loading: 'Chargement...',
  logOut: 'Déconnexion',
  micDisabled: 'Accès au micro refusé par l\'utilisateur',
  myDevice: 'Mon Appareil',
  noDuplicates: 'Impossible d\'ajouter le fichier "%{fileName}", il existe déjà',
  noFilesFound: 'Vous n\'avez aucun fichier ou dossier ici',
  noInternetConnection: 'Pas de connexion à Internet',
  noMoreFilesAllowed: 'Impossible d\'ajouter de nouveaux fichiers: en cours de chargement ',
  openFolderNamed: 'Ouvrir %{name}',
  pause: 'Pause',
  pauseUpload: 'Mettre en pause le téléchargement',
  paused: 'En pause',
  poweredBy: 'Propulsé par %{uppy}',
  processingXFiles: {
    '0': 'Traitement de %{smart_count} fichier',
    '1': 'Traitement de %{smart_count} fichiers',
    '2': 'Traitement de %{smart_count} fichiers',
  },
  recording: 'Enregistrement',
  recordingLength: 'Durée d\'enregistrement %{recording_length}',
  recordingStoppedMaxSize: 'L\'enregistrement s\'est arrété car la taille du fichier dépasse la limite',
  removeFile: 'Effacer le fichier %{file}',
  resetFilter: 'Réinitialiser filtre',
  resume: 'Reprendre',
  resumeUpload: 'Reprendre le téléchargement',
  retry: 'Réessayer',
  retryUpload: 'Réessayer le téléchargement',
  save: 'Sauvegarder',
  saveChanges: 'Sauvegarder les modifications',
  selectFileNamed: 'Sélectionner le fichier %{name}',
  selectX: {
    '0': 'Sélectionner %{smart_count}',
    '1': 'Sélectionner %{smart_count}',
    '2': 'Sélectionner %{smart_count}',
  },
  smile: 'Souriez !',
  startRecording: 'Commencer l\'enregistrement vidéo',
  stopRecording: 'Arrêter l\'enregistrement vidéo',
  streamActive: 'Stream actif',
  streamPassive: 'Stream passif',
  submitRecordedFile: 'Envoyer la vidéo enregistrée',
  takePicture: 'Prendre une photo',
  timedOut: 'Téléchargement bloqué durant %{seconds} secondes, annulation.',
  unselectFileNamed: 'Désélectionner le fichier %{name}',
  upload: 'Télécharger',
  uploadComplete: 'Téléchargement terminé',
  uploadFailed: 'Le téléchargement a échoué',
  uploadPaused: 'Téléchargement mis en pause',
  uploadXFiles: {
    '0': 'Télécharger %{smart_count} fichier',
    '1': 'Télécharger %{smart_count} fichiers',
    '2': 'Télécharger %{smart_count} fichiers',
  },
  uploadXNewFiles: {
    '0': 'Télécharger +%{smart_count} fichier',
    '1': 'Télécharger +%{smart_count} fichiers',
    '2': 'Télécharger +%{smart_count} fichiers',
  },
  uploading: 'Téléchargement en cours',
  uploadingXFiles: {
    '0': 'Téléchargement de %{smart_count} fichier',
    '1': 'Téléchargement de %{smart_count} fichiers',
    '2': 'Téléchargement de %{smart_count} fichiers',
  },
  xFilesSelected: {
    '0': '%{smart_count} fichier sélectionné',
    '1': '%{smart_count} fichiers sélectionnés',
    '2': '%{smart_count} fichiers sélectionnés',
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} autre fichier ajouté',
    '1': '%{smart_count} autres fichiers ajoutés',
    '2': '%{smart_count} autres fichiers ajoutés',
  },
  xTimeLeft: '%{time} restantes',
  youCanOnlyUploadFileTypes: 'Vous pouvez seulement télécharger: %{types}',
  youCanOnlyUploadX: {
    '0': 'Vous pouvez seulement télécharger %{smart_count} fichier',
    '1': 'Vous pouvez seulement télécharger %{smart_count} fichiers',
    '2': 'Vous pouvez seulement télécharger %{smart_count} fichiers',
  },
  youHaveToAtLeastSelectX: {
    '0': 'Vous devez sélectionner au moins %{smart_count} fichier',
    '1': 'Vous devez sélectionner au moins %{smart_count} fichiers',
    '2': 'Vous devez sélectionner au moins %{smart_count} fichiers',
  },
}

if (typeof Uppy !== 'undefined') {
  globalThis.Uppy.locales.fr_FR = fr_FR
}

export default fr_FR
